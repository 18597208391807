<template>
  <div class="change-root-password">
    <base-input
      v-for="item in fields"
      :ref="item.name"
      :key="item.name"
      v-model="item.value"
      :name="item.name"
      :type="item.type"
      :required="item.required || false"
      :invalid="item.invalid"
      :tooltip="item.tooltip || null"
      use-reactive-validation
      class="signup__field"
      @focus="onFocus(item)"
      @blur="onBlur(item)"
    >
      {{ $t(`fields.${item.name}.label`) }}
      <template v-if="item.name === 'passwd'" #icon>
        <plain-button
          icon="key"
          color="dark"
          tabindex="-1"
          class="change-root-password__gen"
          @click="generatePass(item)"
        />
      </template>
      <template v-if="item.name === 'passwd' && secureLevel !== 'none'" #labelEnd>
        <span :class="`${secureColors[secureLevel]}-color`">
          {{ $t(`security.${secureLevel}`) }}
        </span>
      </template>
    </base-input>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import generatePassword from '@/mixins/generatePassword';
export default {
  name: 'ChangeRootPassword',
  components: { BaseInput },
  mixins: [generatePassword],
  data() {
    return {
      fields: [
        {
          name: 'passwd',
          type: 'password',
          invalid: false,
          value: '',
          tooltip: this.$t('fields.passwd.tooltip'),
          required: true,
        },
      ],
      minPassLength: 10,
    };
  },
  computed: {
    isValid() {
      return this.fields.every(
        i =>
          !i.required ||
          (i.required && i.name === 'passwd'
            ? i.value.trim().length >= this.minPassLength
            : i.value)
      );
    },
    formData() {
      return this.fields.reduce((acc, i) => {
        acc[i.name] = i.value.trim();
        return acc;
      }, {});
    },
  },
  watch: {
    isValid: function (val) {
      this.$emit('ready', val);
    },
    formData: function (val) {
      this.$emit('change', val);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "fields": {
      "passwd": {
        "label": "Введите новый пароль",
        "tooltip": "Сгенерировать надежный пароль"
      }
    }
  }
}
</i18n>
