<template>
  <div class="vps-detail-access">
    <transition name="fade" mode="out-in">
      <base-loader v-if="loading || (status === 2 && !serverInfo)" />
      <div v-else-if="root" class="vps-detail-access__content">
        <page-block :title="$t('reset.title')" class="vps-detail-access__block">
          <main-card>
            <!--            {{ isGuestTools ? $t('reset.text') : $t('reset.textold') }}-->
            {{ $t('reset.textold') }}
            <base-button slot="bodyEnd" class="vps-detail-access__btn" @click="changePasswd">
              {{ $t('reset.btn') }}
            </base-button>
          </main-card>
        </page-block>
        <page-block v-if="false" :title="$t('ssh.title')" class="vps-detail-access__block">
          <main-card>
            {{ $t('ssh.text') }}
            <base-button slot="bodyEnd" class="vps-detail-access__btn">
              {{ $t('ssh.btn') }}
            </base-button>
          </main-card>
        </page-block>
      </div>
      <base-alert
        v-else
        :title="$t(`accessError.${error}.title`)"
        icon="warn"
        middle
        class="vps-detail-access__alert"
      />
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import ChangeRootPassword from '../components/ChangeRootPassword';
import showErrorModal from '@/mixins/showErrorModal';
import updateServerInfo from '../../../mixins/updateServerInfo';
import Vue from 'vue';
export default {
  name: 'VpsAccess',
  components: {
    MainCard,
    BaseAlert,
  },
  mixins: [showErrorModal, updateServerInfo],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof VpsTariff,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      changePassIsValid: false,
      changePassFormData: {},
      changePassModal: null,
    };
  },
  computed: {
    status() {
      return this.tariff.status.code;
    },
    serverInfo() {
      return this.tariff.serverInfo;
    },
    serverState() {
      return this.tariff.serverState.toString();
    },
    root() {
      return this.serverInfo ? this.serverInfo.root : false;
    },
    error() {
      if (!this.serverInfo) return 'disabled';
      else if (!this.root) return 'access';
      else return '';
    },
    // isGuestTools() {
    //   return this.newFeatures && this.guestToolsStatus === 1;
    // },
  },
  watch: {
    changePassIsValid: function (val) {
      Vue.set(this.changePassModal.footer.confirm.props, 'disabled', !val);
    },
  },
  methods: {
    changePasswd() {
      this.$modals.open({
        name: 'ChangeRootPasswd',
        title: this.$t('reset.title'),
        component: ChangeRootPassword,
        on: {
          ready: val => (this.changePassIsValid = val),
          change: val => (this.changePassFormData = val),
        },
        onOpen: inst => (this.changePassModal = inst),
        onClose: () => (this.changePassModal = null),
        footer: {
          confirm: {
            props: { title: this.$t('save'), disabled: true },
            on: { click: this.sendChangePassword },
          },
          cancel: {
            props: { title: this.$t('cancel') },
            on: { click: this.$modals.close },
          },
        },
      });
    },
    sendChangePassword() {
      const payload = {
        id: this.tariff.id,
        serverId: this.tariff.serverId,
        method: 'patch',
        params: { Username: 'root', Password: this.changePassFormData.passwd },
      };
      this.$store
        .dispatch('moduleVPS/setServerAction', payload)
        .then(data => {
          this.oldState = data.State;
          this.$modals.open({
            name: 'ChangeRootPasswdSuccess',
            text: this.$t('changePassSuccess'),
            footer: {
              cancel: {
                props: { title: this.$t('good') },
                on: { click: this.$modals.close },
              },
            },
          });
          this.$modals.close({ name: this.changePassModal.name });
          this.updateServerInfo();
        })
        .catch(e => {
          this.showError(e);
        });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "reset": {
        "title": "Изменение root пароля",
        "text": "После нажатия на кнопку сбросить пароль, новый пароль от root пользователя будет отправлен на ваш email, указанный при регистрации.",
        "textold": "После нажатия на кнопку сбросить пароль, сервер будет автоматически остановлен. Новый пароль от root пользователя будет отправлен на ваш email, указанный при регистрации.",
        "btn": "Изменить пароль"
      },
      "ssh": {
        "title": "SSH-ключи пользователя root",
        "text": "Вы можете добавить новый SSH-ключ или выбрать существующий",
        "btn": "Добавить SSH-ключ"
      },
      "accessError": {
        "disabled": {
          "title": "Эта страница недоступна для данной услуги"
        },
        "access": {
          "title": "У вас нет доступа к данной странице"
        }
      },
      "changePassSuccess": "Изменение пароля для пользователя root прошло успешно"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail-access {
  &__alert {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
